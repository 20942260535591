import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import { tokenAPI } from "../../api/users";

let refreshTimeout;

function checkTokenExpirationTime(token) {
  if (token) {
    let tokenExpirationTime = jwt.decode(token).exp * 1000;
    let currentTime = new Date();
    currentTime = currentTime.getTime() + 90000; // minute and a half addition in miliseconds, a "buffer" time
    return tokenExpirationTime - currentTime;
  } else {
    return 0;
  }
}

function refreshToken() {
  if (
    checkTokenExpirationTime(
      localStorage.getItem("tamnavaPortalRefreshToken")
    ) <= 0
  ) {
    logout();
  } else if (localStorage.getItem("tamnavaPortalRefreshToken")) {
    tokenAPI({ token: localStorage.getItem("tamnavaPortalRefreshToken") })
      .then((response) => {
        localStorage.setItem(
          "tamnavaPortalLoginToken",
          response.data.accessToken
        );
        localStorage.setItem(
          "tamnavaPortalRefreshToken",
          response.data.refreshToken
        );
        tokenRefresh();
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Autentifikacija nije uspela: Email adresa ne postoji u bazi"
        ) {
          toast.error(
            "Refresh token nije uspeo: Email adresa ne postoji u bazi",
            {
              containerId: "Error",
            }
          );
        } else {
          toast.error("Refresh token nije uspeo", {
            containerId: "Error",
          });
        }
        logout();
      });
  }
}

export function tokenRefresh() {
  if (localStorage.getItem("tamnavaPortalLoginToken")) {
    if (
      checkTokenExpirationTime(
        localStorage.getItem("tamnavaPortalLoginToken")
      ) <= 0
    ) {
      refreshToken();
    } else {
      refreshTimeout = setTimeout(
        refreshToken,
        checkTokenExpirationTime(
          localStorage.getItem("tamnavaPortalLoginToken")
        )
      );
    }
  }
}

function logout() {
  localStorage.clear();
  clearTimeout(refreshTimeout);
  window.location.href = "/";
}

// Format DD.MM.YYYY hh:mm
export function getDateTimeFromString(stringDate) {
  let dateFormat = new Date(stringDate);
  return (
    dateFormat.getDate() +
    "." +
    (dateFormat.getMonth() + 1) +
    "." +
    dateFormat.getFullYear() +
    " " +
    dateFormat.toLocaleTimeString().slice(0, 4)
  );
}

// Format DD.MM.YYYY
export function getDateFromString(stringDate) {
  let dateFormat = new Date(stringDate);
  return (
    dateFormat.getUTCDate() +
    "." +
    (dateFormat.getUTCMonth() + 1) +
    "." +
    dateFormat.getUTCFullYear()
  );
}

import React, { createContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { ToastContainer, toast } from "react-toastify";
import UserProvider from "./context/UserContext";
import { tokenRefresh } from "./common/security/token.function";
import PrivateRoute from "./common/security/protected.route";
import LandingPage from "./pages/landing/landing";
import Login from "./pages/login/login";
import Signup from "./pages/signup/signup";
import Parts from "./pages/parts/parts";
import Users from "./pages/users/users";
import PageNotFound from "./pages/page-not-found/page-not-found";
import Services from "./pages/services/services";
import Department from "./pages/department/department";
import Lubrication from "./pages/lubrication/lubrication";
import Conveyor from "./pages/lubrication/conveyor/conveyor";
import Maps from "./pages/maps/maps";

export const UserContext = createContext();

function App() {
  useEffect(() => {
    localStorage.removeItem("tamnavaPortalTokenRefreshIsActive");
    tokenRefresh();
  }, []);
  return (
    <UserProvider>
      <Router>
        <div className="App">
          <ToastContainer
            enableMultiContainer
            containerId={"Error"}
            position={toast.POSITION.TOP_RIGHT}
          />
          <ToastContainer
            enableMultiContainer
            containerId={"Success"}
            position={toast.POSITION.TOP_RIGHT}
          />
          <ToastContainer
            enableMultiContainer
            containerId={"Warning"}
            position={toast.POSITION.TOP_LEFT}
          />
          <ToastContainer
            enableMultiContainer
            limit={1}
            autoClose={3000}
            containerId={"Info"}
            position={toast.POSITION.TOP_CENTER}
          />
          <Switch>
            {/* public routes for all users */}
            <Route exact path="/" component={LandingPage} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            {/* private routes for authenticated users */}
            <PrivateRoute path="/department" component={Department} />
            <PrivateRoute path="/maps" component={Maps} />
            <PrivateRoute exact path="/lubrication" component={Lubrication} />
            <PrivateRoute
              path="/lubrication/conveyor/:id"
              component={Conveyor}
            />
            <PrivateRoute path="/services" component={Services} />
            <PrivateRoute path="/parts" component={Parts} />
            <PrivateRoute path="/users" component={Users} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;

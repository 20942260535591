import React from 'react'
import Spinner from "react-bootstrap/Spinner";

function Spinners() {
  return (
    <div className="loading-spinner">
      <Spinner animation="border" variant="dark" />
      <Spinner animation="border" variant="dark" />
      <Spinner animation="border" variant="dark" />
    </div>
  );
}

export default Spinners;

import React, { useEffect, useReducer } from "react";
import { useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import { toast } from "react-toastify";
import Page from "../../components/page/page";
import Spinners from "../../components/spinners/spinners";
import Pagination from "../../components/pagination/pagination";
import MotorLubricationItem from "./lubrication-item/motor-lubrication-item";
import { getMotorsAPI } from "../../api/motors";

const initialState = {
  loading: true,
  error: "",
  motors: [],
  page: {
    current: 1,
    limit: 10,
    totalPages: 0,
    totalDocuments: 0,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS": {
      return {
        loading: false,
        error: "",
        motors: action.payload.content,
        page: action.payload.page,
      };
    }
    case "ERROR": {
      return {
        loading: false,
        error: action.payload,
        motors: [],
        page: {
          current: 1,
          limit: 10,
          totalPages: 0,
          totalDocuments: 0,
        },
      };
    }
    case "PAGE_CHANGE": {
      return initialState;
    }
    default:
      return state;
  }
};

function Lubrication() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  const getNotLubricatedMotors = (page = 1, limit = 10) => {
    let params = {
      page: page,
      limit: limit,
      searchByNextLubricationDate: new Date(),
    };
    getMotorsAPI(params).then((response) => {
      if (response.message) {
        dispatch({ type: "ERROR", payload: response.message });
        toast.error(
          `Učitavanje motora iz baze nije uspelo ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        dispatch({ type: "SUCCESS", payload: { ...response.data } });
      }
    });
  };

  useEffect(() => {
    window.scroll(0, 0);
    const parsed = queryString.parse(history.location.search);
    if (!parsed.page) {
      getNotLubricatedMotors();
    } else if (
      parsed &&
      parsed.page &&
      Number.isInteger(Number(parsed.page)) &&
      Number(parsed.page) > 0
    ) {
      getNotLubricatedMotors(parsed.page);
    } else {
      history.push("/page-not-found");
    }
  }, [history]);

  const onPageChange = (page) => {
    window.scroll(0, 0);
    history.push({
      pathname: "/lubrication",
      search: `?page=${page}`,
    });
    dispatch({ type: "PAGE_CHANGE" });
    getNotLubricatedMotors(page);
  };

  const motorsLubricationList = () => {
    return state.motors.map((currentMotor, index) => {
      return (
        <MotorLubricationItem
          motor={currentMotor}
          key={currentMotor._id}
          index={index}
          current={state.page.current}
        />
      );
    });
  };

  return (
    <Page>
      <div className="container-lubrication">
        Tračni transporteri:
        <div>
          <Link to="/lubrication/conveyor/sup">
            <button className="lubrication-conveyor-button">SUP</button>
          </Link>
          <Link to="/lubrication/conveyor/su1">
            <button className="lubrication-conveyor-button">SU-1</button>
          </Link>
          <Link to="/lubrication/conveyor/su2">
            <button className="lubrication-conveyor-button">SU-2</button>
          </Link>
          <Link to="/lubrication/conveyor/su3zp">
            <button className="lubrication-conveyor-button">SU-3ZP</button>
          </Link>
          <Link to="/lubrication/conveyor/su3ip">
            <button className="lubrication-conveyor-button">SU-3IP</button>
          </Link>
          <Link to="/lubrication/conveyor/su4">
            <button className="lubrication-conveyor-button">SU-4</button>
          </Link>
          <Link to="/lubrication/conveyor/mrs">
            <button className="lubrication-conveyor-button">MRS</button>
          </Link>
          <Link to="/lubrication/conveyor/su5">
            <button className="lubrication-conveyor-button">SU-5</button>
          </Link>
          <Link to="/lubrication/conveyor/v1">
            <button className="lubrication-conveyor-button">V-1</button>
          </Link>
          <Link to="/lubrication/conveyor/e1">
            <button className="lubrication-conveyor-button">E-1</button>
          </Link>
          <Link to="/lubrication/conveyor/omj">
            <button className="lubrication-conveyor-button">OMJ</button>
          </Link>
          <Link to="/lubrication/conveyor/o2">
            <button className="lubrication-conveyor-button">O-2</button>
          </Link>
          <Link to="/lubrication/conveyor/o1">
            <button className="lubrication-conveyor-button">O-1</button>
          </Link>
          <Link to="/lubrication/conveyor/v2">
            <button className="lubrication-conveyor-button">V-2</button>
          </Link>
          <Link to="/lubrication/conveyor/e2">
            <button className="lubrication-conveyor-button">E-2</button>
          </Link>
        </div>
        {!state.loading ? (
          <React.Fragment>
            {state.page.totalDocuments > 0 && (
              <React.Fragment>
                <h3>Motori koji nisu podmazani na vreme</h3>
                <table className="table table-stripped table-bordered table-hover">
                  <thead className="thead">
                    <tr>
                      <th>Broj</th>
                      <th>Objekat</th>
                      <th>Pogon</th>
                      <th>Serijski broj</th>
                      <th>Datum podmazivanja</th>
                      <th>Kasni [dana]</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.motors.length > 0 && motorsLubricationList()}
                  </tbody>
                </table>
              </React.Fragment>
            )}
            {state.motors.length === 0 && (
              <div>Nema motora koji nisu podmazani</div>
            )}
            <Pagination
              show={state.page.totalDocuments > 10}
              onChange={onPageChange}
              limit={state.page.limit}
              current={state.page.current}
              total={state.page.totalDocuments}
            />
          </React.Fragment>
        ) : (
          <Spinners />
        )}
      </div>
    </Page>
  );
}

export default Lubrication;

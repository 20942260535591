import React from "react";
import Modal from "react-bootstrap/Modal";

function ServiceDescriptionModal(props) {
  const { show, onHide, description, executors } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Opis poslova i izvršioci</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-service-description">{description}</div>
        <div className="modal-service-executors">{executors}</div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-info" onClick={onHide}>
          Zatvori
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceDescriptionModal;

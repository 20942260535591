import { get, erase, patch, post } from "./controller.js";

export function getUsersAPI(params) {
  return get("/api/users", params);
}

export function deleteUserByIdAPI(id) {
  return erase(`/api/users/${id}`);
}

export function updateUserByIdAPI(id, data) {
  return patch(`/api/users/update/${id}`, data);
}

export function registerAPI(data) {
  return post("/api/users/signup", data);
}

export function loginAPI(data) {
  return post("/api/users/login", data);
}

export function tokenAPI(data) {
  return post("/api/users/token", data);
}

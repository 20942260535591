import React from "react";
import { getDateTimeFromString } from "../../../../common/utils";

function ReportItem(props) {
  const { report, current = 1, index } = props;
  return (
    <tr>
      <td>{(current - 1) * 10 + index + 1}</td>
      <td>
        {report.URL ? (
          <a
            // .replace("upload", "upload/fl_attachment")
            href={report.URL}
            download
            title="Preuzmite"
          >
            <i className="icon-pdf fas fa-file-pdf fa-2x"></i>
          </a>
        ) : (
          ""
        )}
      </td>
      <td>{getDateTimeFromString(report.date)}</td>
    </tr>
  );
}

export default ReportItem;

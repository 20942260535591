import { get, erase, patch, post } from "./controller.js";

export function getPathsAPI(params) {
  return get("/api/paths", params);
}

export function deletePathByIdAPI(id) {
  return erase(`/api/paths/${id}`);
}

export function updatePathByIdAPI(id, data) {
  return patch(`/api/paths/update/${id}`, data);
}

export function addPathAPI(data) {
  return post("/api/paths/add", data);
}

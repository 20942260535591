import React, { useEffect } from "react";
import Page from "../../components/page/page";

function Department() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Page>
      <div className="container-department">
        <h1>ELEKTRO SLUŽBA "TAMNAVA - ISTOČNO POLJE"</h1>
        <h3>Menadžment</h3>
        <div className="managament">
          <p>
            <b>Upravnik:</b> Radoje Mitrović
          </p>
          <p>
            <b>Pomoćnik upravnika za kop:</b> Nikola Vasiljević
          </p>
          <p>
            <b>Pomoćnik upravnika za Drobilanu:</b> Nikola Tadić
          </p>
        </div>
        <h3>Sistemi</h3>
        <div className="systems">
          <p>
            <b>Šef BTO-1:</b>
          </p>
          <p>
            <b>Poslovođa BTO-1:</b> Predrag Pakić
          </p>
          <p>
            <b>Šef BTO-2:</b> Aleksandar Đorđević
          </p>
          <p>
            <b>Poslovođa BTO-1:</b> Mileta Živković
          </p>
          <p>
            <b>Šef BTU:</b> Miodrag Golubović
          </p>
          <p>
            <b>Poslovođa BTU:</b> Darko Ćirić
          </p>
          <p>
            <b>Šef preventivnog održavanja Drobilane:</b>
          </p>
          <p>
            <b>Poslovođa preventivnog održavanja Drobilane:</b> Radovan Vuković
          </p>
          <p>
            <b>Šef operativnog održavanja Drobilane:</b> Zoran Milošević
          </p>
          <p>
            <b>Poslovođa operativnog održavanja Drobilane:</b>
          </p>
        </div>
        <h3>Radionice</h3>
        <div className="departments">
          <p>
            <b>Šef grupe za bagere:</b> Zoran Jezdimirović
          </p>
          <p>
            <b>Poslovođa grupe za bagere:</b> Đorđe Petrović
          </p>
          <p>
            <b>Šef grupe za trake:</b> Siniša Elez
          </p>
          <p>
            <b>Poslovođa grupe za trake:</b> Slobodan Aleksić
          </p>
          <p>
            <b>Šef grupe za napajanje:</b>
          </p>
          <p>
            <b>Poslovođa grupe za napajanje:</b> Saša Milovanović
          </p>
          <p>
            <b>Šef grupe za elektroniku:</b> Ivan Mijailović
          </p>
          <p>
            <b>Poslovođa grupe za elektroniku:</b> Čedomir Jelić
          </p>
          <p>
            <b>Šef grupe za telekomunikacije:</b> Živojin Teofilović
          </p>
          <p>
            <b>Poslovođa grupe za telekomunikacije:</b> Predrag Kraguljac
          </p>
          <p>
            <b>Šef grupe za radioničko održavanje:</b>
          </p>
          <p>
            <b>Poslovođa grupe za radioničko održavanje:</b> Zoran Jovanović
          </p>
          <p>
            <b>Šef grupe za odvodnjavanje:</b> Perica Grujičić
          </p>
          <p>
            <b>Poslovođa grupe za odvodnjavanje:</b> Dragan Mijatović
          </p>
        </div>
        <h3>Smena</h3>
        <div className="shift">
          <p>
            <b>Smenski inženjer smena A:</b> Nikola Marinković
          </p>
          <p>
            <b>Smenski inženjer smena B:</b> Dušan Popović
          </p>
          <p>
            <b>Smenski inženjer smena C:</b> Milan Rosić
          </p>
          <p>
            <b>Smenski inženjer smena D:</b> Dejan Broćilo
          </p>
          <p>
            <b>Smenski inženjer rezerva:</b> Miroslav Maksimović
          </p>
        </div>
      </div>
    </Page>
  );
}

export default Department;

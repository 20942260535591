import React, { useState, useEffect, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import queryString from "query-string";
import Spinners from "../../components/spinners/spinners";
import Spinner from "react-bootstrap/Spinner";
import { Formik, Form, Field } from "formik";
import Page from "../../components/page/page";
import ServiceItem from "./service-item/service-item";
import ServiceModal from "./service-modal/service-modal";
import ServiceDescriptionModal from "./service-modal/service-description-modal";
import Pagination from "../../components/pagination/pagination";
import {
  getServicesAPI,
  deleteServiceByIdAPI,
  updateServiceByIdAPI,
} from "../../api/services";

const initialState = {
  loading: true,
  error: "",
  services: [],
  page: {
    current: 1,
    limit: 10,
    totalPages: 0,
    totalDocuments: 0,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS": {
      return {
        loading: false,
        error: "",
        services: action.payload.content,
        page: action.payload.page,
      };
    }
    case "ERROR": {
      return {
        loading: false,
        error: action.payload,
        services: [],
        page: {
          current: 1,
          limit: 10,
          totalPages: 0,
          totalDocuments: 0,
        },
      };
    }
    case "PAGE_CHANGE": {
      return initialState;
    }
    default:
      return state;
  }
};

function Services() {
  const formikRef = useRef(null);
  const history = useHistory();
  const initialValues = {
    searchServices: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showServiceModal, setServiceModal] = useState(false);
  const [showServiceDescriptionModal, setServiceDescriptionModal] = useState(
    false
  );
  const [serviceDescription, setServiceDescription] = useState("");
  const [serviceExecutors, setServiceExecutors] = useState("");
  const [modalTitle, setModalTitle] = useState("Nalog za servis");
  const [modalButton, setModalButton] = useState("Dodaj");
  const [service, setService] = useState();

  const getServices = (page = 1, limit = 10, searchServices = "") => {
    let params = {
      page: page,
      limit: limit,
      searchServices: searchServices ? searchServices : null,
    };
    getServicesAPI(params).then((response) => {
      if (response.message) {
        dispatch({ type: "ERROR", payload: response.message });
        toast.error(
          `Učitavanje servisa iz baze nije uspelo ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        dispatch({ type: "SUCCESS", payload: { ...response.data } });
      }
    });
  };

  const deleteService = (id) => {
    deleteServiceByIdAPI(id).then((response) => {
      if (response.message) {
        toast.error(
          `Brisanje servisa u baze nije uspelo zbog: ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        dispatch({ type: "PAGE_CHANGE" });
        history.push({
          pathname: "/services",
          search: `?page=1`,
        });
        getServices();
      }
    });
  };

  const closeService = (id) => {
    const serviceData = {
      status: "Završen",
    };
    updateServiceByIdAPI(id, serviceData).then((response) => {
      if (response.message) {
        toast.error(
          `Zatvaranje servisa u bazi nije uspelo ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        getServices();
      }
    });
  };

  const onPageChange = (page) => {
    window.scroll(0, 0);
    history.push({
      pathname: "/services",
      search: `?page=${page}`,
    });
    dispatch({ type: "PAGE_CHANGE" });
    getServices(page);
  };

  const openServiceDescription = (description, executors) => {
    setServiceDescription(description);
    setServiceExecutors(executors);
    setServiceDescriptionModal(true);
  };

  const editService = (service) => {
    setServiceModal(true);
    setService(service);
    setModalTitle("Izmeni podatke");
    setModalButton("Izmeni");
  };

  const addService = () => {
    setServiceModal(true);
    setModalTitle("Nalog za servis");
    setService();
  };

  useEffect(() => {
    window.scroll(0, 0);
    const parsed = queryString.parse(history.location.search);
    if (!parsed.page) {
      getServices();
    } else if (
      parsed &&
      parsed.page &&
      Number.isInteger(Number(parsed.page)) &&
      Number(parsed.page) > 0
    ) {
      getServices(parsed.page);
    } else {
      history.push("/page-not-found");
    }
  }, [history]);

  const servicesList = () => {
    return state.services.map((currentService, index) => {
      return (
        <ServiceItem
          service={currentService}
          deleteService={deleteService}
          editService={editService}
          closeService={closeService}
          openServiceDescription={openServiceDescription}
          key={currentService._id}
          index={index}
          current={state.page.current}
        />
      );
    });
  };
  const onSubmit = (searchData) => {
    dispatch({ type: "PAGE_CHANGE" });
    getServices(1, 10, searchData.searchServices);
  };

  const getAllServices = () => {
    window.scroll(0, 0);
    history.push({
      pathname: "/services",
      search: `?page=1`,
    });
    dispatch({ type: "PAGE_CHANGE" });
    getServices();
    formikRef.current.handleReset();
  };
  return (
    <Page>
      <div className="container-services">
        <button className="service-button" onClick={addService}>
          Nalog za servis
        </button>
        <div className="container-search-services">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {(formikProps) => {
              return (
                <Form>
                  <div className="container-search-form">
                    <div className="form-group">
                      <Field
                        type="text"
                        id="searchService"
                        name="searchServices"
                        placeholder="Objekat"
                      />
                    </div>
                  </div>
                  <button type="submit" className="service-button">
                    Pretražite{" "}
                    {formikProps.isSubmitting && state.loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
        <button className="service-button" onClick={getAllServices}>
          Svi nalozi
        </button>
        {!state.loading ? (
          <React.Fragment>
            <h3>Lista naloga za servise</h3>
            <table className="table table-stripped table-bordered table-hover">
              <thead className="thead">
                <tr>
                  <th>Broj</th>
                  <th>Objekat</th>
                  <th>Opis servisa</th>
                  <th>Datum</th>
                  <th>Status</th>
                  <th>Otvorio</th>
                  <th>Akcije</th>
                </tr>
              </thead>
              <tbody>{state.services.length > 0 && servicesList()}</tbody>
            </table>
            {state.services.length === 0 && (
              <div>Nema servisa u bazi po traženom kriterijumu</div>
            )}
            <Pagination
              show={state.page.totalDocuments > 10}
              onChange={onPageChange}
              limit={state.page.limit}
              current={state.page.current}
              total={state.page.totalDocuments}
            />
          </React.Fragment>
        ) : (
          <Spinners />
        )}
      </div>
      <ServiceModal
        show={showServiceModal}
        onHide={() => setServiceModal(false)}
        onUpdate={() => getServices()}
        service={service}
        title={modalTitle}
        button={modalButton}
      />
      <ServiceDescriptionModal
        show={showServiceDescriptionModal}
        onHide={() => setServiceDescriptionModal(false)}
        description={serviceDescription}
        executors={serviceExecutors}
      />
    </Page>
  );
}

export default Services;

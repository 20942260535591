import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../App";
import Modal from "react-bootstrap/Modal";
import DatePicker, { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../../../components/inputTextError/textError";
import { addMotorAPI, updateMotorByIdAPI } from "../../../../api/motors";
registerLocale("sr", sr);

const options = [
  { value: "star", label: "Y" },
  { value: "delta", label: "Δ" },
];

function MotorModal(props) {
  const {
    show,
    onHide,
    onUpdate,
    title,
    position,
    conveyorName,
    motorData,
  } = props;
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (motorData) {
      setFormValues({
        serialNumber: motorData.serialNumber,
        manufacturer: motorData.manufacturer,
        type: motorData.type,
        power: motorData.power,
        voltage: motorData.voltage,
        current: motorData.current,
        speed: motorData.speed,
        powerFactor: motorData.powerFactor,
        connection: motorData.connection,
        axialHeight: motorData.axialHeight,
        bearingsDE: motorData.bearingsDE,
        bearingsNDE: motorData.bearingsNDE,
        greaseType: motorData.greaseType,
        greaseQuantity: motorData.greaseQuantity,
        builtInDate: motorData.builtInDate
          ? new Date(motorData.builtInDate)
          : "",
        lubricatedDate: motorData.lubricatedDate
          ? new Date(motorData.lubricatedDate)
          : "",
        lubricationTimeInterval: motorData.lubricationTimeInterval,
        diagnosticsDate: motorData.diagnosticsDate
          ? new Date(motorData.diagnosticsDate)
          : "",
        plateImage: "",
      });
    } else {
      setFormValues({
        serialNumber: "",
        manufacturer: "",
        type: "",
        power: "",
        voltage: "",
        current: "",
        speed: "",
        powerFactor: "",
        connection: "star",
        axialHeight: "",
        bearingsDE: "",
        bearingsNDE: "",
        greaseType: "",
        greaseQuantity: "",
        builtInDate: "",
        lubricatedDate: "",
        lubricationTimeInterval: "",
        diagnosticsDate: "",
        plateImage: "",
      });
    }
  }, [motorData, user]);
  const [plateImage, setPlateImage] = useState(null);
  const [imageFormatError, setImageFormatError] = useState(false);
  const [formValues, setFormValues] = useState();
  const initialValues = {
    serialNumber: "",
    manufacturer: "",
    type: "",
    power: "",
    voltage: "",
    current: "",
    speed: "",
    powerFactor: "",
    connection: "",
    axialHeight: "",
    bearingsDE: "",
    bearingsNDE: "",
    greaseType: "",
    greaseQuantity: "",
    builtInDate: "",
    lubricatedDate: "",
    lubricationTimeInterval: "",
    diagnosticsDate: "",
    plateImage: null,
  };
  const validationSchema = Yup.object({
    serialNumber: Yup.string().required("Ovo polje je obavezno"),
    manufacturer: "",
    type: "",
    power: "",
    voltage: "",
    current: "",
    speed: "",
    powerFactor: "",
    axialHeight: "",
    bearingsDE: "",
    bearingsNDE: "",
    greaseType: "",
    greaseQuantity: "",
    builtInDate: Yup.string().required("Ovo polje je obavezno"),
    lubricatedDate: "",
    lubricationTimeInterval: "",
    diagnosticsDate: "",
  });
  const onSubmit = (values, onSubmitProps) => {
    let builtInDateOneHourAdded = new Date(values.builtInDate);
    let lubricatedDateOneHourAdded = values.lubricatedDate
      ? new Date(values.lubricatedDate)
      : "";
    let diagnosticsDateOneHourAdded = values.diagnosticsDate
      ? new Date(values.diagnosticsDate)
      : "";
    if (imageFormatError) {
      onSubmitProps.setSubmitting(false);
    } else {
      let nextLubricationDate = "";
      let diagnosticsDate = "";
      if (values.lubricatedDate && values.lubricationTimeInterval) {
        let lubricatedDate = new Date(values.lubricatedDate);
        lubricatedDate.setDate(
          lubricatedDate.getDate() + values.lubricationTimeInterval
        );
        nextLubricationDate = lubricatedDate.setTime(
          lubricatedDate.getTime() + 60 * 60 * 1000
        );
      }
      diagnosticsDate = diagnosticsDateOneHourAdded
        ? (diagnosticsDate = new Date(
            diagnosticsDateOneHourAdded.setTime(
              diagnosticsDateOneHourAdded.getTime() + 60 * 60 * 1000
            )
          ))
        : "";

      let lubricatedDate = lubricatedDateOneHourAdded
        ? new Date(
            lubricatedDateOneHourAdded.setTime(
              lubricatedDateOneHourAdded.getTime() + 60 * 60 * 1000
            )
          )
        : "";
      const formData = new FormData();
      formData.append("serialNumber", values.serialNumber);
      formData.append("manufacturer", values.manufacturer);
      formData.append("type", values.type);
      formData.append("power", values.power);
      formData.append("voltage", values.voltage);
      formData.append("current", values.current);
      formData.append("speed", values.speed);
      formData.append("powerFactor", values.powerFactor);
      formData.append("connection", values.connection);
      formData.append("axialHeight", values.axialHeight);
      formData.append("bearingsDE", values.bearingsDE);
      formData.append("bearingsNDE", values.bearingsNDE);
      formData.append("greaseType", values.greaseType);
      formData.append("greaseQuantity", values.greaseQuantity);
      formData.append(
        "builtInDate",
        new Date(
          builtInDateOneHourAdded.setTime(
            builtInDateOneHourAdded.getTime() + 60 * 60 * 1000
          )
        )
      );
      formData.append("lubricatedDate", lubricatedDate);
      formData.append(
        "lubricationTimeInterval",
        values.lubricationTimeInterval
      );
      formData.append("nextLubricationDate", nextLubricationDate);
      formData.append("diagnosticsDate", diagnosticsDate);
      formData.append("conveyorName", conveyorName);
      formData.append("position", position);
      formData.append("plateImage", plateImage);
      if (motorData.serialNumber) {
        updateMotorByIdAPI(motorData._id, formData)
          .then(() => {
            onHide();
            onUpdate();
          })
          .catch((error) => {
            toast.error(
              "Izmena podataka o motoru u bazi nije uspelo zbog:",
              error.response.data.message,
              {
                containerId: "Error",
              }
            );
            onSubmitProps.setSubmitting(false);
          });
      } else {
        addMotorAPI(formData)
          .then(() => {
            onHide();
            onUpdate();
          })
          .catch((error) => {
            toast.error(
              "Dodavanje motora u bazu nije uspelo zbog:",
              error.response.data.message,
              {
                containerId: "Error",
              }
            );
            onSubmitProps.setSubmitting(false);
          });
      }
    }
  };
  const onUploadImage = (event) => {
    const fileType = event.target.files[0].type;
    if (
      !fileType
        .substring(fileType.indexOf("image/") + 6)
        .match(/(jpg|jpeg|png|gif)$/i)
    ) {
      setImageFormatError(true);
    } else {
      setImageFormatError(false);
      setPlateImage(event.target.files[0]);
    }
  };
  const resetForm = () => {
    onHide();
    setPlateImage(null);
  };
  return (
    <Modal
      show={show}
      onHide={resetForm}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-form-motor">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formikProps) => {
              return (
                <Form autoComplete="off">
                  <div className="form-group">
                    <label htmlFor="serialNumber">SERIJSKI BROJ</label>
                    <Field
                      type="text"
                      id="serialNumber"
                      name="serialNumber"
                      className="form-input"
                    />
                    <ErrorMessage name="serialNumber" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="manufacturer">PROIZVOĐAČ</label>
                    <Field
                      type="text"
                      id="manufacturer"
                      name="manufacturer"
                      className="form-input"
                    />
                    <ErrorMessage name="manufacturer" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="type">TIP</label>
                    <Field
                      type="text"
                      id="type"
                      name="type"
                      className="form-input"
                    />
                    <ErrorMessage name="type" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="power">SNAGA [kW]</label>
                    <Field
                      type="text"
                      id="power"
                      name="power"
                      className="form-input"
                    />
                    <ErrorMessage name="power" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="voltage">NAPON [VAC]</label>
                    <Field
                      type="text"
                      id="voltage"
                      name="voltage"
                      className="form-input"
                    />
                    <ErrorMessage name="voltage" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="current">STRUJA [A]</label>
                    <Field
                      type="text"
                      id="current"
                      name="current"
                      className="form-input"
                    />
                    <ErrorMessage name="current" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="speed">BROJ OBRTAJA [rpm]</label>
                    <Field
                      type="text"
                      id="speed"
                      name="speed"
                      className="form-input"
                    />
                    <ErrorMessage name="speed" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="powerFactor">cosΦ</label>
                    <Field
                      type="text"
                      id="powerFactor"
                      name="powerFactor"
                      className="form-input"
                    />
                    <ErrorMessage name="powerFactor" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="connection">NAČIN POVEZIVANJA</label>
                    <Field as="select" id="connection" name="connection">
                      {options.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label htmlFor="axialHeight">OSNA VISINA [mm]</label>
                    <Field
                      type="text"
                      id="axialHeight"
                      name="axialHeight"
                      className="form-input"
                    />
                    <ErrorMessage name="axialHeight" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="bearingsDE">LEŽAJEVI DE</label>
                    <Field
                      type="text"
                      id="bearingsDE"
                      name="bearingsDE"
                      className="form-input"
                    />
                    <ErrorMessage name="bearingsDE" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="bearingsNDE">LEŽAJEVI NDE</label>
                    <Field
                      type="text"
                      id="bearingsNDE"
                      name="bearingsNDE"
                      className="form-input"
                    />
                    <ErrorMessage name="bearingsNDE" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="greaseType">TIP MASTI</label>
                    <Field
                      type="text"
                      id="greaseType"
                      name="greaseType"
                      className="form-input"
                    />
                    <ErrorMessage name="greaseType" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="greaseQuantity">KOLIČINA MASTI [g]</label>
                    <Field
                      type="text"
                      id="greaseQuantity"
                      name="greaseQuantity"
                      className="form-input"
                    />
                    <ErrorMessage name="greaseQuantity" component={TextError} />
                  </div>
                  <div className="form-group date">
                    <label htmlFor="builtInDate">UGRAĐEN</label>
                    <Field name="builtInDate">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;
                        return (
                          <DatePicker
                            id="builtInDate"
                            {...field}
                            dateFormat="dd/MM/yyyy"
                            locale="sr"
                            selected={value}
                            onChange={(val) =>
                              setFieldValue("builtInDate", val)
                            }
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="builtInDate" component={TextError} />
                  </div>
                  <div className="form-group date">
                    <label htmlFor="lubricatedDate">PODMAZAN</label>
                    <Field name="lubricatedDate">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;
                        return (
                          <DatePicker
                            id="lubricatedDate"
                            {...field}
                            dateFormat="dd/MM/yyyy"
                            locale="sr"
                            selected={value}
                            onChange={(val) =>
                              setFieldValue("lubricatedDate", val)
                            }
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="lubricatedDate" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lubricationTimeInterval">
                      VREMENSKI INTERVAL [dani]
                    </label>
                    <Field
                      type="number"
                      id="lubricationTimeInterval"
                      name="lubricationTimeInterval"
                      className="form-input"
                    />
                    <ErrorMessage
                      name="lubricationTimeInterval"
                      component={TextError}
                    />
                  </div>
                  <div className="form-group date">
                    <label htmlFor="diagnosticsDate">DIJAGNOSTIKA</label>
                    <Field name="diagnosticsDate">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;
                        return (
                          <DatePicker
                            id="diagnosticsDate"
                            {...field}
                            dateFormat="dd/MM/yyyy"
                            locale="sr"
                            selected={value}
                            onChange={(val) =>
                              setFieldValue("diagnosticsDate", val)
                            }
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage
                      name="diagnosticsDate"
                      component={TextError}
                    />
                  </div>
                  <div className="form-group">
                    <label>PLOČICA</label>
                    <input
                      type="file"
                      name="plateImage"
                      accept="image/*"
                      multiple={false}
                      className="form-input-file"
                      onChange={(event) => onUploadImage(event)}
                    />
                    {imageFormatError && (
                      <div className="error">
                        Slika mora biti u formatu: jpg|jpeg|png|gif
                      </div>
                    )}
                  </div>
                  <div className="modal-form-footer">
                    <button
                      disabled={formikProps.isSubmitting}
                      className="btn btn-info cancel"
                      onClick={resetForm}
                    >
                      Izađi
                    </button>
                    <button
                      type="submit"
                      disabled={
                        imageFormatError ||
                        formikProps.isSubmitting ||
                        (user && user.role !== "adminDiagnostic")
                      }
                      className="btn btn-success save"
                    >
                      Sačuvaj{" "}
                      {formikProps.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MotorModal;

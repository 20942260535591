import React from 'react';

function TextErrror(props) {
  return (
    <div className="error">
      {props.children}
    </div>
  )
}

export default TextErrror;

import { get, erase, patch, post } from "./controller.js";

export function getMarkersAPI(params) {
  return get("/api/markers", params);
}

export function deleteMarkerByIdAPI(id) {
  return erase(`/api/markers/${id}`);
}

export function updateMarkerByIdAPI(id, data) {
  return patch(`/api/markers/update/${id}`, data);
}

export function addMarkerAPI(data) {
  return post("/api/markers/add", data);
}

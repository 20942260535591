import React from "react";
import { useHistory } from "react-router";
import "./footer.scss";

function Footer() {
  const history = useHistory();
  const getYear = () => {
    return " - © " + new Date().getFullYear() + ". Sva prava zadržana.";
  };
  const onTerms = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };
  const onPrivacy = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };
  const onDisclaimer = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };
  const onFaq = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };
  const onContactUs = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };
  const goToLink = () => {
    history.push("/");
  };
  return (
    <div className="footer-copyright container">
      <div className="container py-2">
        <div className="row py-4">
          <div className="col-xl-1 col-lg-0 col-md-0 col-sm-0"></div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-logo">
            <img
              alt="Portal TIP"
              width="40"
              height="40"
              src="../../../../../images/logo.png"
              onClick={goToLink}
            />
            <span className="name-next-to-logo">Portal TIP</span>
            <p>
              <strong>Portal TIP</strong>
              {getYear()}
            </p>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-xs-12">
            <div className="footer-subtitle">Društvene mreže</div>
            <ul className="footer-social-icons social-icons social-icons-clean social-icons-icon-light mb-3">
              <li className="social-icons-facebook">
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  title="Facebook"
                  rel="noreferrer noopener"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li className="social-icons-instagram">
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  title="Twitter"
                  rel="noreferrer noopener"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li className="social-icons-linkedin">
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  title="Linkedin"
                  rel="noreferrer noopener"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div className="footer-subtitle">Kontakt</div>
            <div className="footer-text">
              <div onClick={onContactUs}>Kontaktirajte nas</div>
            </div>
            <div className="footer-text">
              <div onClick={onFaq}>Pitanja</div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div className="footer-subtitle">Zakoni</div>
            <div className="footer-text">
              <div onClick={onTerms}>Uslovi korišćenja</div>
            </div>
            <div className="footer-text">
              <div onClick={onPrivacy}>Privatnost i zaštita podataka</div>
            </div>
            <div className="footer-text">
              <div onClick={onDisclaimer}>Odgovornost</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

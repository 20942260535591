import { get, erase, patch, post } from "./controller.js";

export function getServicesAPI(params) {
  return get("/api/services", params);
}

export function deleteServiceByIdAPI(id) {
  return erase(`/api/services/${id}`);
}

export function updateServiceByIdAPI(id, data) {
  return patch(`/api/services/update/${id}`, data);
}

export function addServiceAPI(data) {
  return post(`/api/services/add`, data);
}

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../components/inputTextError/textError";
import Page from "../../components/page/page";
import { registerAPI } from "../../api/users";

function Signup() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const initialValues = {
    firstName: "",
    lastName: "",
    job: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Ovo polje je obavezno"),
    lastName: Yup.string().required("Ovo polje je obavezno"),
    job: Yup.string().required("Ovo polje je obavezno"),
    email: Yup.string()
      .required("Ovo polje je obavezno")
      .email("Nevažeči email format"),
    password: Yup.string()
      .required("Ovo polje je obavezno")
      .min(8, "Lozinka mora da ima minimum 8 karaktera"),
    confirmPassword: Yup.string()
      .required("Ovo polje je obavezno")
      .oneOf([Yup.ref("password"), ""], "Lozinke nisu iste"),
  });
  const onSubmit = (signupData, onSubmitProps) => {
    registerAPI(signupData)
      .then(() => {
        history.push("/login");
      })
      .catch((error) => {
        if (error.response.data.message === "Email already exists in DB") {
          onSubmitProps.setFieldError(
            "email",
            "Email već postoji u bazi podataka"
          );
        } else if (
          error.response.data.message &&
          error.response.data.message !== "Email already exists in DB"
        ) {
          toast.error(
            `Prijava nije uspela zbog: ${error.response.data.message}`,
            {
              containerId: "Error",
            }
          );
        }
        onSubmitProps.setSubmitting(false);
      });
  };
  return (
    <Page>
      <div className="container-form-signup">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formikProps) => {
            return (
              <Form autoComplete="off">
                <h3>Registrujte svoj nalog</h3>
                <div className="form-group">
                  <label htmlFor="firstName">Ime</label>
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="form-input"
                  />
                  <ErrorMessage name="firstName" component={TextError} />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Prezime</label>
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="form-input"
                  />
                  <ErrorMessage name="lastName" component={TextError} />
                </div>
                <div className="form-group">
                  <label htmlFor="job">Radno mesto</label>
                  <Field
                    type="text"
                    id="job"
                    name="job"
                    className="form-input"
                  />
                  <ErrorMessage name="job" component={TextError} />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    className="form-input"
                    autoComplete="off"
                  />
                  <ErrorMessage name="email" component={TextError} />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Lozinka</label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    className="form-input"
                    autoComplete="off"
                  />
                  <ErrorMessage name="password" component={TextError} />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmPassword">Potvrdite lozinku</label>
                  <Field
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    className="form-input"
                    autoComplete="off"
                  />
                  <ErrorMessage name="confirmPassword" component={TextError} />
                </div>
                <button
                  type="submit"
                  disabled={!formikProps.isValid || formikProps.isSubmitting}
                  className="btn btn-success"
                >
                  Registrujte se{" "}
                  {formikProps.isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </Form>
            );
          }}
        </Formik>
        <p className="account-label">
          Imate nalog na Portalu elektro službe TIP?
        </p>
        <Link to="/login" className="btn btn-primary">
          Prijavite se
        </Link>
      </div>
    </Page>
  );
}

export default Signup;

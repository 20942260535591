import React, { useContext } from "react";
import { UserContext } from "../../App";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";

function Page(props) {
  const { user } = useContext(UserContext);
  return (
    <React.Fragment>
      <div className="whole-page-body">
        {user && user.auth && <Navbar />}
        <div className="page-below-navigation">
          <div className="container">
            <div className="row">
              <div className="col-12 no-padding">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Page;

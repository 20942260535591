import axios from "axios";

function onFulfilled(response) {
  if (
    (response.data &&
      response.data.page &&
      response.data.page.totalDocuments > 0 &&
      response.data.page.current < 0) ||
    (response.data &&
      response.data.page &&
      response.data.page.totalPages > 0 &&
      response.data.page.current > response.data.page.totalPages)
  ) {
    window.location.href = "/page-not-found";
  } else {
    return response;
  }
}

function onRejected(error) {
  switch (error.response.status) {
    // Bad Request
    case 400: {
      return error;
    }
    // Unauthorized
    case 401: {
      return error;
    }
    // Forbidden
    case 403: {
      return error;
    }
    // Not Found
    case 404: {
      return error;
    }
    default: {
      return error;
    }
  }
}

export function get(url, params) {
  return axios({
    method: "get",
    url: url,
    headers: {
      authorization:
        "Bearer " + localStorage.getItem("tamnavaPortalLoginToken"),
      "content-type": "application/json",
    },
    params: { ...params },
  }).then(
    (response) => onFulfilled(response),
    (error) => onRejected(error)
  );
}

export function erase(url, params) {
  return axios({
    method: "delete",
    url: url,
    headers: {
      authorization:
        "Bearer " + localStorage.getItem("tamnavaPortalLoginToken"),
      "content-type": "application/json",
    },
    params: { ...params },
  }).then(
    (response) => onFulfilled(response),
    (error) => onRejected(error)
  );
}

export function patch(url, data) {
  return axios({
    method: "patch",
    url: url,
    data: data,
    headers: {
      authorization:
        "Bearer " + localStorage.getItem("tamnavaPortalLoginToken"),
      "content-type": "application/json",
    },
  });
}

export function post(url, data) {
  return axios({
    method: "post",
    url: url,
    data: data,
    headers: {
      authorization:
        "Bearer " + localStorage.getItem("tamnavaPortalLoginToken"),
      "content-type": "application/json",
    },
  });
}

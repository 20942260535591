import React, { useEffect, useReducer } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import Spinners from "../../../components/spinners/spinners";
import Page from "../../../components/page/page";
import MotorItem from "./motor-item/motor-item";
import { getMotorsAPI } from "../../../api/motors";

const motorDataDefault = {
  serialNumber: "",
  manufacturer: "",
  type: "",
  power: "",
  voltage: "",
  current: "",
  speed: "",
  powerFactor: "",
  connection: "",
  axialHeight: "",
  bearingsDE: "",
  bearingsNDE: "",
  greaseType: "",
  greaseQuantity: "",
  builtInDate: "",
  lubricatedDate: "",
  lubricationTimeInterval: "",
  nextLubricationDate: "",
  diagnosticsDate: "",
  plateImageURL: "",
  conveyorName: "",
  position: "",
};

const initialState = {
  loading: true,
  error: "",
  motors: {
    motorDataUL: { ...motorDataDefault },
    motorDataUR: { ...motorDataDefault },
    motorDataDL: { ...motorDataDefault },
    motorDataDR: { ...motorDataDefault },
  },
  conveyorName: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS": {
      return {
        loading: false,
        error: "",
        motors: action.payload.motors,
        conveyorName: action.payload.conveyorName,
      };
    }
    case "ERROR": {
      return {
        loading: false,
        error: action.payload.error,
        motors: {
          motorDataUL: { ...motorDataDefault },
          motorDataUR: { ...motorDataDefault },
          motorDataDL: { ...motorDataDefault },
          motorDataDR: { ...motorDataDefault },
        },
        conveyorName: action.payload.conveyorName,
      };
    }
    case "PAGE_CHANGE": {
      return initialState;
    }
    case "DELETE_DATA_UL": {
      return {
        ...state,
        motors: {
          motorDataUL: { ...motorDataDefault },
        },
      };
    }
    case "DELETE_DATA_UR": {
      return {
        ...state,
        motors: {
          motorDataUR: { ...motorDataDefault },
        },
      };
    }
    case "DELETE_DATA_DL": {
      return {
        ...state,
        motors: {
          motorDataDL: { ...motorDataDefault },
        },
      };
    }
    case "DELETE_DATA_DR": {
      return {
        ...state,
        motors: {
          motorDataDR: { ...motorDataDefault },
        },
      };
    }
    default:
      return state;
  }
};

function Conveyor() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const getMotorsData = () => {
    let params = {
      searchByConveyorName: history.location.pathname
        .substring(history.location.pathname.lastIndexOf("/") + 1)
        .toUpperCase(),
    };
    getMotorsAPI(params).then((response) => {
      if (response.message) {
        let conveyorName = history.location.pathname
          .substring(history.location.pathname.lastIndexOf("/") + 1)
          .toUpperCase();
        dispatch({
          type: "ERROR",
          payload: { error: response.message, conveyorName: conveyorName },
        });
        toast.error(
          `Učitavanje podataka o motorima iz baze nije uspelo ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        let motors = {
          motorDataUL: { ...motorDataDefault },
          motorDataUR: { ...motorDataDefault },
          motorDataDL: { ...motorDataDefault },
          motorDataDR: { ...motorDataDefault },
        };
        let conveyorName = history.location.pathname
          .substring(history.location.pathname.lastIndexOf("/") + 1)
          .toUpperCase();
        if (response.data.content.length > 0) {
          for (let index = 0; index < response.data.content.length; index++) {
            if (response.data.content[index].position === "GL") {
              motors.motorDataUL = response.data.content[index];
            }
            if (response.data.content[index].position === "GD") {
              motors.motorDataUR = response.data.content[index];
            }
            if (response.data.content[index].position === "DL") {
              motors.motorDataDL = response.data.content[index];
            }
            if (response.data.content[index].position === "DD") {
              motors.motorDataDR = response.data.content[index];
            }
          }
          dispatch({
            type: "SUCCESS",
            payload: { motors, conveyorName },
          });
        } else {
          dispatch({
            type: "SUCCESS",
            payload: { motors, conveyorName },
          });
        }
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getMotorsData();
  }, []);
  return (
    <Page>
      {!state.loading ? (
        <div className="conveyor-container">
          <h3>TRAČNI TRANSPORTER {state.conveyorName}</h3>
          <div className="motor-row">
            <MotorItem
              heading="PODACI O GORNJEM LEVOM MOTORU"
              data={state.motors.motorDataUL}
              conveyorName={state.conveyorName}
              onUpdate={() => getMotorsData()}
              onErase={() =>
                dispatch({
                  type: "DELETE_DATA_UL",
                })
              }
            />
            <MotorItem
              heading="PODACI O GORNJEM DESNOM MOTORU"
              data={state.motors.motorDataUR}
              conveyorName={state.conveyorName}
              onUpdate={() => getMotorsData()}
              onErase={() =>
                dispatch({
                  type: "DELETE_DATA_UR",
                })
              }
            />
          </div>
          <div className="motor-row">
            <MotorItem
              heading="PODACI O DONJEM LEVOM MOTORU"
              data={state.motors.motorDataDL}
              conveyorName={state.conveyorName}
              onUpdate={() => getMotorsData()}
              onErase={() =>
                dispatch({
                  type: "DELETE_DATA_DL",
                })
              }
            />
            <MotorItem
              heading="PODACI O DONJEM DESNOM MOTORU"
              data={state.motors.motorDataDR}
              conveyorName={state.conveyorName}
              onUpdate={() => getMotorsData()}
              onErase={() =>
                dispatch({
                  type: "DELETE_DATA_DR",
                })
              }
            />
          </div>
        </div>
      ) : (
        <Spinners />
      )}
    </Page>
  );
}

export default Conveyor;

// Authentication service - use Local Storage for getting token
class AuthService {
  isAuthenticated() {
    if (localStorage.getItem("tamnavaPortalLoginToken")) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    return this.authenticated;
  }
}

export default new AuthService();

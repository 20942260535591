import React from "react";
import { getDateFromString } from "../../../common/utils";

function MotorLubricationItem(props) {
  const { current, index } = props;
  const {
    conveyorName,
    position,
    serialNumber,
    nextLubricationDate,
  } = props.motor;
  const expiredDays = () => {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date();
    const secondDate = new Date(nextLubricationDate);
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return diffDays;
  };
  return (
    <tr className="table-danger">
      <td>{(current - 1) * 10 + index + 1}</td>
      <td>{conveyorName}</td>
      <td>{position}</td>
      <td>{serialNumber}</td>
      <td>{getDateFromString(nextLubricationDate)}</td>
      <td>{expiredDays()}</td>
    </tr>
  );
}

export default MotorLubricationItem;

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Polyline,
  DrawingManager,
} from "@react-google-maps/api";
import { toast } from "react-toastify";
import Page from "../../components/page/page";
import Legend from "./legend/legend";
import Spinners from "../../components/spinners/spinners";
import MarkerModal from "./marker-modal/marker-modal";
import PathModal from "./path-modal/path-modal";
import { UserContext } from "../../App";
import { getDateTimeFromString } from "../../common/utils";
import { getMarkersAPI } from "../../api/markers";
import { getPathsAPI } from "../../api/paths";
import { powerLineColors } from "../../common/consts";

const libraries = ["drawing"];
const containerStyle = {
  width: "100%",
  height: "75vh",
};
const center = {
  lat: 44.4986166894979,
  lng: 20.23468746079279,
};
const options = {
  mapTypeId: "satellite",
  zoomControl: true,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
};
function Maps() {
  const { user } = useContext(UserContext);
  const toastId = React.useRef(null);
  const [showMarkerModal, setMarkerModal] = useState(false);
  const [marker, setMarker] = useState("");
  const [loadingMarkers, setLoadingMarkers] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState();
  const [buttonMarker, setButtonMarker] = useState("Dodaj");
  const [isSticky, setSticky] = useState(false);
  const [showPathModal, setPathModal] = useState(false);
  const [path, setPath] = useState("");
  const [loadingPaths, setLoadingPaths] = useState(true);
  const [paths, setPaths] = useState([]);
  const [selectedPath, setSelectedPath] = useState();
  const [buttonPath, setButtonPath] = useState("Dodaj");
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA98Sj1pWfWD7hM8Tu3Mn6Rlo0DZ4Vz9hA",
    libraries: libraries,
  });
  const polyLineOptionsFunc = (path) => {
    let strokeColor = "#000000";
    let powerLine = path.powerLineNumber + " " + path.substation;
    for (let index = 0; index < powerLineColors.length; index++) {
      if (powerLine === powerLineColors[index].label) {
        strokeColor = powerLineColors[index].value;
        break;
      }
    }
    const polylineOptions = {
      strokeColor: strokeColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FFF000",
      fillOpacity: 0.35,
      clickable: true,
      draggable: false,
      editable: false,
      visible: true,
      radius: 30000,
      zIndex: 1,
    };
    const lineSymbol = {
      path: "M 0,-1 0,1",
      strokeColor: strokeColor,
      strokeOpacity: 1,
      scale: 4,
    };
    const polylineOptionsDashed = {
      strokeOpacity: 0,
      icons: [
        {
          icon: lineSymbol,
          offset: "0",
          repeat: "20px",
        },
      ],
    };
    return path.lineType === "cable" ? polylineOptions : polylineOptionsDashed;
  };
  const onMapClick = useCallback((event) => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      time: "",
      cabinetType: "",
      cabinetMark: "",
      substation: "",
      powerLineNumber: "",
      addedBy: "",
    });
    setMarkerModal(true);
  }, []);
  const mapRef = useRef;
  const onMapLoad = useCallback(
    (map) => {
      mapRef.current = map;
    },
    [mapRef]
  );
  const getMarkers = () => {
    getMarkersAPI().then((response) => {
      if (response.message) {
        toast.error(
          `Učitavanje markera iz baze nije uspelo ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        setMarkers(response.data.content);
      }
      setLoadingMarkers(false);
    });
  };
  const getPaths = () => {
    getPathsAPI().then((response) => {
      if (response.message) {
        toast.error(
          `Učitavanje kablova iz baze nije uspelo ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        setPaths(response.data.content);
      }
      setLoadingPaths(false);
    });
  };
  const closeMarkerModal = () => {
    setMarkerModal(false);
    setButtonMarker("Dodaj");
  };
  const closePathModal = () => {
    setPathModal(false);
    setButtonPath("Dodaj");
  };
  const onDeletePath = () => {
    closePathModal();
    setPath();
    window.location.reload();
  };
  const onPolylineComplete = (polyline) => {
    setPathModal(true);
    setPath(polyline.getPath().getArray());
  };
  useEffect(() => {
    window.scroll(0, 0);
    window.addEventListener("scroll", handleScroll);
    getMarkers();
    getPaths();
    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);
  const drawingOptions = {
    drawingControl: true,
    drawingControlOptions: {
      position: 2,
      drawingModes: ["polyline"],
    },
  };
  return (
    <Page>
      {isLoaded && !loadingMarkers && !loadingPaths ? (
        <React.Fragment>
          <div
            className={`container-legend-sticky-wrapper${
              isSticky ? " container-legend-sticky" : ""
            }`}
            ref={ref}
          >
            <Legend />
          </div>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={16}
            options={options}
            onClick={user && user.role === "moderatorSupply" && onMapClick}
            onLoad={onMapLoad}
          >
            {user && user.role === "moderatorSupply" && (
              <DrawingManager
                options={drawingOptions}
                onPolylineComplete={onPolylineComplete}
              />
            )}
            <React.Fragment>
              {markers.map((marker) => (
                <Marker
                  key={marker.time}
                  position={{
                    lat: marker.latitude,
                    lng: marker.longitude,
                  }}
                  icon={{
                    url: `/images/${marker.cabinetType}.svg`,
                    scaledSize: new window.google.maps.Size(60, 60),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(30, 30),
                  }}
                  onClick={() => {
                    setSelectedMarker(marker);
                    setButtonMarker("Izmeni");
                  }}
                />
              ))}
              {selectedMarker ? (
                <InfoWindow
                  position={{
                    lat: selectedMarker.latitude,
                    lng: selectedMarker.longitude,
                  }}
                  onCloseClick={() => setSelectedMarker()}
                >
                  <div
                    className="container-info-window"
                    onClick={() => {
                      if (user && user.role === "moderatorSupply") {
                        setMarkerModal(true);
                      }
                    }}
                  >
                    <p>
                      {selectedMarker.cabinetType +
                        " " +
                        selectedMarker.cabinetMark}
                    </p>
                    <p>
                      DV{" "}
                      {selectedMarker.powerLineNumber +
                        " TS " +
                        selectedMarker.substation}
                    </p>
                    <p>{selectedMarker.addedBy}</p>
                    <p>{getDateTimeFromString(selectedMarker.time)}</p>
                  </div>
                </InfoWindow>
              ) : null}
              {paths.map((path) => (
                <Polyline
                  key={path.time}
                  path={path.path}
                  options={polyLineOptionsFunc(path)}
                  strokeColor={"#FF0000"}
                  onMouseOver={() => {
                    if (!toast.isActive(toastId.current)) {
                      toastId.current = toast.info(` ${path.cableType}`, {
                        containerId: "Info",
                      });
                    }
                  }}
                  onClick={() => {
                    setPathModal(true);
                    setSelectedPath(path);
                    setButtonPath("Izmeni");
                  }}
                />
              ))}
            </React.Fragment>
            <MarkerModal
              show={showMarkerModal}
              onHide={() => closeMarkerModal()}
              onUpdate={() => getMarkers()}
              marker={buttonMarker === "Dodaj" ? marker : selectedMarker}
              button={buttonMarker}
            />
            <PathModal
              show={showPathModal}
              onHide={() => closePathModal()}
              onDelete={() => onDeletePath()}
              onUpdate={() => window.location.reload()}
              path={buttonPath === "Dodaj" ? path : selectedPath}
              button={buttonPath}
            />
          </GoogleMap>
        </React.Fragment>
      ) : (
        <Spinners />
      )}
    </Page>
  );
}

export default Maps;

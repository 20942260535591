import { get, erase, patch, post } from "./controller.js";

export function getPartsAPI(params) {
  return get("/api/parts", params);
}

export function deletePartByIdAPI(id) {
  return erase(`/api/parts/${id}`);
}

export function updatePartByIdAPI(id, data) {
  return patch(`/api/parts/update/${id}`, data);
}

export function addPartAPI(data) {
  return post(`/api/parts/add`, data);
}

import React, { useState, useEffect, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../components/inputTextError/textError";
import PartItem from "./part-item/part-item";
import Page from "../../components/page/page";
import Spinner from "react-bootstrap/Spinner";
import Spinners from "../../components/spinners/spinners";
import ImageViewModal from "../../components/modals/image-view-modal";
import PartModal from "./part-modal/part-modal";
import Pagination from "../../components/pagination/pagination";
import { getPartsAPI, deletePartByIdAPI } from "../../api/parts";

const initialState = {
  loading: true,
  error: "",
  parts: [],
  page: {
    current: 1,
    limit: 10,
    totalPages: 0,
    totalDocuments: 0,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS": {
      return {
        loading: false,
        error: "",
        parts: action.payload.content,
        page: action.payload.page,
      };
    }
    case "ERROR": {
      return {
        loading: false,
        error: action.payload,
        parts: [],
        page: {
          current: 1,
          limit: 10,
          totalPages: 0,
          totalDocuments: 0,
        },
      };
    }
    case "PAGE_CHANGE": {
      return initialState;
    }
    default:
      return state;
  }
};

function PartsList() {
  const formikRef = useRef(null);
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [part, setPart] = useState();
  const [modalTitle, setModalTitle] = useState("Dodaj novi deo");
  const [modalButton, setModalButton] = useState("Dodaj");
  const [showPartModal, setPartModal] = useState(false);
  const [sortArrow, setSortArrow] = useState("");
  const getParts = (
    page = 1,
    limit = 10,
    searchByDescription = "",
    searchByOrderNumber = "",
    searchByManufacturer = "",
    sortBy = ""
  ) => {
    let params = {
      page: page,
      limit: limit,
      searchByDescription: searchByDescription ? searchByDescription : null,
      searchByOrderNumber: searchByOrderNumber ? searchByOrderNumber : null,
      searchByManufacturer: searchByManufacturer ? searchByManufacturer : null,
      sortBy: sortBy ? sortBy : "",
    };
    getPartsAPI(params).then((response) => {
      if (response.message) {
        dispatch({ type: "ERROR", payload: response.message });
        toast.error(
          `Učitavanje delova iz baze nije uspelo ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        dispatch({ type: "SUCCESS", payload: { ...response.data } });
      }
    });
  };
  const initialValues = {
    searchByDescription: "",
    searchByOrderNumber: "",
    searchByManufacturer: "",
  };
  const validationSchema = Yup.object({
    searchByDescription: Yup.string(),
    searchByOrderNumber: Yup.string(),
    searchByManufacturer: Yup.string(),
  });
  const onSubmit = (searchData) => {
    dispatch({ type: "PAGE_CHANGE" });
    getParts(
      1,
      10,
      searchData.searchByDescription,
      searchData.searchByOrderNumber,
      searchData.searchByManufacturer,
      ""
    );
  };
  const [imagePart, setImagePart] = useState("");
  const [showImageView, setImageView] = useState(null);
  useEffect(() => {
    window.scroll(0, 0);
    const parsed = queryString.parse(history.location.search);
    if (!parsed.page) {
      getParts();
    } else if (
      parsed &&
      parsed.page &&
      Number.isInteger(Number(parsed.page)) &&
      Number(parsed.page) > 0
    ) {
      getParts(parsed.page);
    } else {
      history.push("/page-not-found");
    }
  }, [history]);
  const deletePart = (id) => {
    deletePartByIdAPI(id).then((response) => {
      if (response.message) {
        toast.error(
          `Brisanje dela u baze nije uspelo zbog: ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        history.push({
          pathname: "/parts",
          search: `?page=1`,
        });
        dispatch({ type: "PAGE_CHANGE" });
        getParts();
      }
    });
  };
  const openImageView = (image) => {
    setImageView(true);
    setImagePart(image);
  };
  const editPart = (part) => {
    setPartModal(true);
    setPart(part);
    setModalTitle("Izmeni podatke");
    setModalButton("Izmeni");
  };

  const addPart = () => {
    setPartModal(true);
    setPart();
    setModalTitle("Dodaj novi deo");
    setModalButton("Dodaj");
  };

  const onPageChange = (page) => {
    window.scroll(0, 0);
    history.push({
      pathname: "/parts",
      search: `?page=${page}`,
    });
    dispatch({ type: "PAGE_CHANGE" });
    getParts(
      page,
      10,
      formikRef.current.values.searchByDescription,
      formikRef.current.values.searchByOrderNumber,
      formikRef.current.values.searchByManufacturer,
      ""
    );
  };

  const partsList = () => {
    return state.parts.map((currentPart, index) => {
      return (
        <PartItem
          part={currentPart}
          editPart={editPart}
          deletePart={deletePart}
          openImageView={openImageView}
          key={currentPart._id}
          index={index}
          current={state.page.current}
        />
      );
    });
  };

  const getAllParts = () => {
    history.push({
      pathname: "/parts",
      search: `?page=1`,
    });
    dispatch({ type: "PAGE_CHANGE" });
    getParts();
    formikRef.current.handleReset();
  };
  const onSortBy = () => {
    if (sortArrow === "") {
      setSortArrow("fas fa-arrow-up");
      getParts(1, 10, "", "", "", "quantity");
    } else {
      setSortArrow("");
      getParts(1, 10, "", "", "", "");
    }
  };
  return (
    <Page>
      <div className="container-parts">
        <button className="part-button" onClick={addPart}>
          Dodaj nov deo
        </button>
        <div className="container-search-parts-filter">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {(formikProps) => {
              return (
                <Form>
                  <div className="container-search-form">
                    <div className="form-group">
                      <Field
                        type="text"
                        id="searchByDescription"
                        name="searchByDescription"
                        placeholder="Opis"
                      />
                      <ErrorMessage
                        name="searchByDescription"
                        component={TextError}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        type="text"
                        id="searchByOrderNumber"
                        name="searchByOrderNumber"
                        placeholder="Porudžbeni kod"
                      />
                      <ErrorMessage
                        name="searchByOrderNumber"
                        component={TextError}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        type="text"
                        id="searchByManufacturer"
                        name="searchByManufacturer"
                        placeholder="Proizvođač"
                      />
                      <ErrorMessage
                        name="searchByManufacturer"
                        component={TextError}
                      />
                    </div>
                    <button type="submit" className="part-button">
                      Pretražite{" "}
                      {formikProps.isSubmitting && state.loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <button className="part-button" onClick={getAllParts}>
          Svi delovi
        </button>
        {!state.loading ? (
          <React.Fragment>
            <h3>Lista rezervnih delova</h3>
            <table className="table table-stripped table-bordered table-hover">
              <thead className="thead">
                <tr>
                  <th>Broj</th>
                  <th>Opis</th>
                  <th>Porudžbeni kod</th>
                  <th>Proizvođač</th>
                  <th
                    className="table-quantity-column"
                    onClick={() => onSortBy()}
                    title="Sortiraj po broju rastuće"
                  >
                    Količina<i className={sortArrow}></i>
                  </th>
                  <th>Objekti</th>
                  <th>Slika</th>
                  <th>Uputstvo</th>
                  <th>Akcije</th>
                </tr>
              </thead>
              <tbody>{state.parts.length > 0 && partsList()}</tbody>
            </table>
            {state.parts.length === 0 && (
              <div>Nema delova u bazi po traženom kriterijumu</div>
            )}
            <Pagination
              show={state.page.totalDocuments > 10}
              onChange={onPageChange}
              limit={state.page.limit}
              current={state.page.current}
              total={state.page.totalDocuments}
            />
            <ImageViewModal
              show={showImageView}
              onHide={() => setImageView(false)}
              imageURL={imagePart}
            />
            <PartModal
              show={showPartModal}
              onHide={() => setPartModal(false)}
              onUpdate={() => getParts()}
              title={modalTitle}
              button={modalButton}
              part={part}
            />
          </React.Fragment>
        ) : (
          <Spinners />
        )}
      </div>
    </Page>
  );
}

export default PartsList;

import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../../../App";
import MotorModal from "../motor-modal/motor-modal";
import MotorReportModal from "../motor-report-modal/motor-report-modal";
import { getDateFromString } from "../../../../common/utils";
import { updateMotorByIdAPI } from "../../../../api/motors";

function MotorItem(props) {
  const { user } = useContext(UserContext);
  const [builtIn, setBuiltIn] = useState(false);
  const [showMotorModal, setMotorModal] = useState(false);
  const [showMotorReportModal, setMotorReportModal] = useState(false);
  const [motorPosition, setMotorPosition] = useState("");
  const { conveyorName } = props;
  const { heading } = props;
  const {
    serialNumber,
    manufacturer,
    type,
    power,
    voltage,
    current,
    speed,
    powerFactor,
    connection,
    axialHeight,
    bearingsDE,
    bearingsNDE,
    greaseType,
    greaseQuantity,
    builtInDate,
    lubricatedDate,
    lubricationTimeInterval,
    nextLubricationDate,
    diagnosticsDate,
    plateImageURL,
  } = props.data;
  const headingText = () => {
    switch (heading) {
      case "PODACI O GORNJEM LEVOM MOTORU":
        setMotorPosition("GL");
        break;
      case "PODACI O GORNJEM DESNOM MOTORU":
        setMotorPosition("GD");
        break;
      case "PODACI O DONJEM LEVOM MOTORU":
        setMotorPosition("DL");
        break;
      case "PODACI O DONJEM DESNOM MOTORU":
        setMotorPosition("DD");
        break;
      default:
        setMotorPosition("");
    }
  };
  useEffect(() => {
    builtInDate ? setBuiltIn(true) : setBuiltIn(false);
    headingText();
  }, []);
  const onChangeBuiltIn = () => {
    if (builtIn) {
      if (serialNumber) {
        updateMotorByIdAPI(props.data._id, {
          conveyorName: "",
          position: "",
        }).then((response) => {
          if (response.message) {
            toast.error(
              "Izmena podataka o motoru u bazi nije uspelo zbog:",
              response.message,
              {
                containerId: "Error",
              }
            );
          }
        });
      } else {
        setBuiltIn(false);
      }
    } else {
      setBuiltIn(true);
    }
  };
  return (
    <div className="motor-container">
      <div className="motor-headline">
        <p>
          {heading}
          <span className="motor-built-in">
            <input
              type="checkbox"
              name="motor"
              disabled={user && user.role !== "adminDiagnostic"}
              checked={builtIn}
              onChange={onChangeBuiltIn}
            />{" "}
            <span>Ugrađen</span>
          </span>
        </p>
      </div>
      <div className="motor-data-row">
        <div className="motor-data">
          <div className="motor-data-row">
            {builtIn && (
              <div className="motor-data">
                <div>
                  SERIJSKI BROJ:
                  {serialNumber}
                </div>
                <div>PROIZVOĐAČ: {manufacturer}</div>
                <div>TIP: {type}</div>
                <div>SNAGA: {power && power !== 0 ? power + "kW" : ""}</div>
                <div>
                  NAPON: {voltage && voltage !== 0 ? voltage + "VAC" : ""}
                </div>
                <div>
                  STRUJA: {current && current !== 0 ? current + "A" : ""}
                </div>
                <div>
                  BROJ OBRTAJA: {speed && speed !== 0 ? speed + "rpm" : ""}
                </div>
                <div>
                  cosΦ: {powerFactor && powerFactor !== 0 ? powerFactor : ""}
                </div>
                <div>
                  NAČIN POVEZIVANJA:{" "}
                  {connection && (connection === "star" ? "Y" : "Δ")}
                </div>
                <div>
                  OSNA VISINA:{" "}
                  {axialHeight && axialHeight !== 0 ? axialHeight + "mm" : ""}
                </div>
                <div>LEŽAJEVI DE: {bearingsDE}</div>
                <div>LEŽAJEVI NDE: {bearingsNDE}</div>
                <div>TIP MASTI: {greaseType}</div>
                <div>
                  KOLIČINA MASTI:{" "}
                  {greaseQuantity && greaseQuantity !== 0
                    ? greaseQuantity + "g"
                    : ""}
                </div>
                <div>
                  UGRAĐEN: {builtInDate ? getDateFromString(builtInDate) : ""}
                </div>
                <div>
                  PODMAZAN:{" "}
                  {lubricatedDate ? getDateFromString(lubricatedDate) : ""}
                </div>
                <div>
                  VREMENSKI INTERVAL:{" "}
                  {lubricationTimeInterval !== 0 ? lubricationTimeInterval : ""}
                </div>
                <div>
                  SL. PODMAZIVANJE:{" "}
                  {nextLubricationDate && nextLubricationDate !== "Invalid Date"
                    ? getDateFromString(nextLubricationDate)
                    : ""}
                </div>
                <div>
                  DIJAGNOSTIKA:{" "}
                  {diagnosticsDate ? getDateFromString(diagnosticsDate) : ""}
                </div>
              </div>
            )}
            {builtIn && (
              <div className="lubrication-data">
                <img
                  className="motor-image"
                  src={plateImageURL}
                  alt="Pločica motora"
                />
                <div className="motor-reports-container">
                  <button
                    className="motor-reports-button"
                    disabled={!serialNumber}
                    onClick={() => setMotorReportModal(true)}
                  >
                    IZVEŠTAJI
                  </button>
                </div>
                <div className="motor-edit-data-container">
                  <button
                    className="motor-edit-data-button"
                    disabled={user && user.role !== "adminDiagnostic"}
                    onClick={() => setMotorModal(true)}
                  >
                    {serialNumber ? "IZMENI PODATKE" : "UNESI PODATKE"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <MotorModal
        show={showMotorModal}
        title={heading}
        onHide={() => setMotorModal(false)}
        onUpdate={props.onUpdate}
        position={motorPosition}
        conveyorName={conveyorName}
        motorData={props.data}
      />
      <MotorReportModal
        show={showMotorReportModal}
        onHide={() => setMotorReportModal(false)}
        position={motorPosition}
        conveyorName={conveyorName}
        serialNumber={serialNumber}
      />
    </div>
  );
}

export default MotorItem;

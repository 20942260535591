export const optionsLineType = [
  { value: "cable", label: "Kablovski" },
  { value: "air", label: "Vazdušni" },
];

export const optionsCableType = [
  { value: "PP41 3x35mm2", label: "P35" },
  { value: "PP41 3x50mm2", label: "P50" },
  { value: "PP41 3x95mm2", label: "P95" },
  { value: "EpN 3x50+3x16mm2", label: "G50" },
  { value: "EpN 3x95+3x16mm2", label: "G95" },
  { value: "EpN 3x120+3x16mm2", label: "G120" },
];

export const optionsSubstations = [
  { value: "IP", label: "IP" },
  { value: "VC", label: "VC" },
  { value: "SK", label: "SK" },
  { value: "SP", label: "SP" },
];

export const powerLineColors = [
  { value: "#ff0000", label: "1 SP" },
  { value: "#ff9999", label: "2 SP" },
  { value: "#ff8080", label: "3 SP" },
  { value: "#ff4d4d", label: "5 SP" },
  { value: "#e60000", label: "6 SP" },
  { value: "#ff3300", label: "7 SP" },
  { value: "#ff9980", label: "8 SP" },
  { value: "#ff704d", label: "9 SP" },
  { value: "#e62e00", label: "10 SP" },
  { value: "#cc0000", label: "16 SP" },
  { value: "#ff6666", label: "17 SP" },
  { value: "#ff3333", label: "18 SP" },
  { value: "#c70505", label: "20 SP" },
  { value: "#b81414", label: "21 SP" },
  { value: "#ff1a1a", label: "22 SP" },
  { value: "#0000ff", label: "3 IP" },
  { value: "#4d4dff", label: "8 IP" },
  { value: "#0000b3", label: "10 IP" },
  { value: "#3333ff", label: "15 IP" },
  { value: "#ffff00", label: "2 SK" },
  { value: "#ffff4d", label: "3 SK" },
  { value: "#ffff99", label: "5 SK" },
  { value: "#f7f7a1", label: "9 SK" },
  { value: "#ffcc00", label: "10 SK" },
  { value: "#ffdb4d", label: "11 SK" },
  { value: "#66ff66", label: "F1 VC" },
  { value: "#00ff00", label: "F2 VC" },
  { value: "#00cc00", label: "F4 VC" },
  { value: "#1aff1a", label: "F5 VC" },
];

export const optionsCabinetType = [
  { value: "NK", label: "NK" },
  { value: "KO", label: "KO" },
  { value: "PO", label: "PO" },
  { value: "RO", label: "RO" },
  { value: "RP", label: "RP" },
  { value: "MR", label: "MR" },
  { value: "TS6_0.4", label: "TS" },
];

import React from "react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/sr_RS";

function PaginationContainer(props) {
  const onChange = (page) => {
    props.onChange(page);
  };
  return (
    props.show && (
      <Pagination
        onChange={onChange}
        limit={props.limit}
        current={props.current}
        total={props.total}
        locale={localeInfo}
        showLessItems={true}
      />
    )
  );
}

export default PaginationContainer;

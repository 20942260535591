import React, { useState, useEffect } from "react";
import jwt from "jsonwebtoken";
import { UserContext } from "../App";
import AuthorizationRole from "../common/security/auth.roles";

export default function User({ children }) {
  const [user, setUser] = useState();
  useEffect(() => {
    addUser();
  }, []);

  const addUser = () => {
    const token = localStorage.getItem("tamnavaPortalLoginToken");
    if (token) {
      setUser(() => ({
        firstName: jwt.decode(token).firstName,
        lastName: jwt.decode(token).lastName,
        job: jwt.decode(token).job,
        email: jwt.decode(token).email,
        role: AuthorizationRole(jwt.decode(token).role),
        auth: true,
      }));
    }
  };

  const logoutUser = () => {
    setUser(() => ({
      firstName: "",
      lastName: "",
      job: "",
      email: "",
      role: "",
      auth: false,
    }));
  };

  return (
    <UserContext.Provider value={{ user, addUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
}

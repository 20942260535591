import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import queryString from "query-string";
import UserItem from "./user-item/user-item";
import Page from "../../components/page/page";
import Pagination from "../../components/pagination/pagination";
import Spinners from "../../components/spinners/spinners";
import UserModal from "./user-modal/user-modal";
import { getUsersAPI, deleteUserByIdAPI } from "../../api/users";

const initialState = {
  loading: true,
  error: "",
  users: [],
  page: {
    current: 1,
    limit: 10,
    totalPages: 0,
    totalDocuments: 0,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS": {
      return {
        loading: false,
        error: "",
        users: action.payload.content,
        page: action.payload.page,
      };
    }
    case "ERROR": {
      return {
        loading: false,
        error: action.payload,
        users: [],
        page: {
          current: 1,
          limit: 10,
          totalPages: 0,
          totalDocuments: 0,
        },
      };
    }
    case "PAGE_CHANGE": {
      return initialState;
    }
    default:
      return state;
  }
};

function UsersList() {
  const history = useHistory();
  const [user, setUser] = useState("");
  const [showEditUserModal, setEditUserModal] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUsers = (page = 1, limit = 10) => {
    let params = {
      page: page,
      limit: limit,
    };
    getUsersAPI(params).then((response) => {
      if (response.message) {
        dispatch({ type: "ERROR", payload: response.message });
        toast.error(
          `Učitavanje korisnika iz baze nije uspelo ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        dispatch({ type: "SUCCESS", payload: { ...response.data } });
      }
    });
  };

  const deleteUser = (id) => {
    deleteUserByIdAPI(id).then((response) => {
      if (response.message) {
        toast.error(
          `Brisanje korisnika u baze nije uspelo zbog: ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        dispatch({ type: "PAGE_CHANGE" });
        history.push({
          pathname: "/users",
          search: `?page=1`,
        });
        getUsers();
      }
    });
  };

  const editUser = (user) => {
    setUser(user);
    setEditUserModal(true);
  };

  const usersList = () => {
    return state.users.map((currentUser, index) => {
      return (
        <UserItem
          currentUser={currentUser}
          deleteUser={deleteUser}
          editUser={editUser}
          key={currentUser._id}
          index={index}
          current={state.page.current}
        />
      );
    });
  };

  const onPageChange = (page) => {
    window.scroll(0, 0);
    history.push({
      pathname: "/users",
      search: `?page=${page}`,
    });
    dispatch({ type: "PAGE_CHANGE" });
    getUsers(page);
  };

  useEffect(() => {
    window.scroll(0, 0);
    const parsed = queryString.parse(history.location.search);
    if (!parsed.page) {
      getUsers();
    } else if (
      parsed &&
      parsed.page &&
      Number.isInteger(Number(parsed.page)) &&
      Number(parsed.page) > 0
    ) {
      getUsers(parsed.page);
    } else {
      history.push("/page-not-found");
    }
  }, [history]);

  return (
    <Page>
      {!state.loading ? (
        <div className="container-table-users">
          <table className="table table-stripped table-bordered table-hover">
            <thead className="thead">
              <tr>
                <th>Broj</th>
                <th>Ime i prezime</th>
                <th>Radno mesto</th>
                <th>Email</th>
                <th>Akcije</th>
              </tr>
            </thead>
            <tbody>{usersList()}</tbody>
          </table>
          <Pagination
            show={state.page.totalDocuments > 10}
            onChange={onPageChange}
            limit={state.page.limit}
            current={state.page.current}
            total={state.page.totalDocuments}
          />
          <UserModal
            show={showEditUserModal}
            onHide={() => setEditUserModal(false)}
            onUpdate={() => getUsers()}
            currentUser={user}
          />
        </div>
      ) : (
        <Spinners />
      )}
    </Page>
  );
}

export default UsersList;

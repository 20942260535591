import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Page from "../../components/page/page";

function LandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Page>
      <div className="container-landing">
        <div className="image">
          <Carousel>
            <Carousel.Item interval={5000}>
              <img
                className="w-50 logo-image"
                src="/images/kolubara.jpg"
                alt="logo"
              />
              <Carousel.Caption>
                <p className="image-text">Ogranak EPS</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="w-50 logo-image"
                src="/images/POLJE_G.jpg"
                alt="Polje G"
              />
              <Carousel.Caption>
                <p>Polje G</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="w-50 logo-image"
                src="/images/G-1.jpg"
                alt="G-1"
              />
              <Carousel.Caption>
                <p>Glodar 1</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="w-50 logo-image"
                src="/images/DROBILANA.jpg"
                alt="Drobilana"
              />
              <Carousel.Caption>
                <p>Drobilana - Objekat 1</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="w-50 logo-image"
                src="/images/G-2_BW-1.jpg"
                alt="Poplavljen kop"
              />
              <Carousel.Caption>
                <p>G-2 i BW-1 poplava</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="header-text">
          Dobrodošli na Portal elektro službe Površinskog kopa "Tamnava-Istočno
          polje"
        </div>
        <div className="sign">
          <Link to="/login" className="btn btn-primary btn-lg">
            Prijava
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Registracija
          </Link>
        </div>
      </div>
    </Page>
  );
}

export default LandingPage;

import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ImageViewModal(props) {
  const { show, onHide, imageURL } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Slika rezervnog dela</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-image-view">
          <img alt="deo" src={imageURL} className="image-view" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Zatvori
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImageViewModal;

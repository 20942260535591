import { get, patch, post } from "./controller.js";

export function getMotorsAPI(params) {
  return get("/api/motors", params);
}

export function updateMotorByIdAPI(id, data) {
  return patch(`/api/motors/update/${id}`, data);
}

export function addMotorAPI(data) {
  return post(`/api/motors/add`, data);
}

function AuthorizationRole(role) {
  switch (role) {
    case "Administrator":
      return "adminManager";
    case "Upravnik":
      return "moderator";
    case "Drobilana":
      return "visitor";
    case "BTO":
      return "visitor";
    case "BTU":
      return "visitor";
    case "Bageri":
      return "visitor";
    case "Trake":
      return "visitor";
    case "Elektronika":
      return "visitor";
    case "Telekomunikacije":
      return "visitor";
    case "Napajanje":
      return "moderatorSupply";
    case "Odvodnjavanje":
      return "visitor";
    case "Eševi":
      return "visitor";
    case "Smena":
      return "visitor";
    case "Dijagnostika":
      return "adminDiagnostic";
    case "Delovi":
      return "adminParts";
    case "NoviKorisnik":
      return "newuser";
    default:
      return "";
  }
}

export default AuthorizationRole;
